/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import './Normal_view.scss';
import { Column } from 'primereact/column';
import Moment from 'react-moment';
import { Toast } from 'primereact/toast';
import BookingParticipantService from '../../../api/BookingParticipantService';
import {
  BookingParticipantState,
  IbookingsParticipantsList,
  IParticipantsFilter,
} from '../../../models/Booking/BookingParticipantsList';
import { InputText } from 'primereact/inputtext';
import { getFlagTextColor } from '../../User/UserFlags/helper';

type ComponentProperties = {
  mode?: boolean;
  id?: number;
  history?: any;
};

const BatchRelationshipViewComponent: React.FC<ComponentProperties> = (props) => {
  // @ts-ignore
  let id: number = props.id;
  const toast = useRef(null);

  const [participants, setParticipants] = useState<IbookingsParticipantsList[]>();
  const [selectedParticipants, setSelectedParticipants] = useState<IbookingsParticipantsList>();
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const [expandedRows, setExpandedRows] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const [bookingId, setbookingId] = useState(0);
  const [completedTreks, setCompletedTreks] = useState<IbookingsParticipantsList[]>();
  let userId: number;

  useEffect(() => {
    const bookingParticipantService = BookingParticipantService;
    let filter: IParticipantsFilter = {
      batchId: id,
    };
    bookingParticipantService.get(0, 100, filter).then((data: any) => {
      setParticipants(data.data);
      setLoading(false);
    });

    if (userId) {
      let filterForUserId: IParticipantsFilter = {
        userId: userId,
      };

      bookingParticipantService.get(0, 100, filterForUserId).then((data: any) => {
        setUserData(data.data);
      });
    }
  }, [bookingId]);

  const onRowExpand = (event: any) => {
    setExpandedRows({ [event.data.id]: false });
  };

  const dateFieldTemplate = (data: any, props: any) => {
    const dateToFormat = data[props.field];
    return (
      <>
        <Moment format="DD-MMM-YY" date={dateToFormat} />
      </>
    );
  };

  const bodyTemplate = (data: any, props: any) => {
    return (
      <>
        <span className="p-column-title">{data[props.field]}</span>
      </>
    );
  };

  const bodyTemplateInnerObject = (data: any, props: any) => {
    const email = data.userDetailsForDisplay[props.field];
    const textColor = getFlagTextColor(data.userDetailsForDisplay, props);
    return (
      <>
        <span style={{ color: textColor }} className="p-column-title">
          <span className="p-column-title">{email}</span>
        </span>
      </>
    );
  };

  const bodyTemplateInnerObjectFullName = (data: any, props: any) => {
    const firstName = data.userDetailsForDisplay[props.field];
    const lastName = data.userDetailsForDisplay.lastName;
    const textColor = getFlagTextColor(data.userDetailsForDisplay, props);
    return (
      <>
        <span style={{ color: textColor }} className="p-column-title">
          <span className="p-column-title">
            {firstName} {lastName}
          </span>
        </span>
      </>
    );
  };

  const participantStatusbodyTemplate = (data: any, props: any) => {
    const bookingParticipantState = data[props.field];

    switch (bookingParticipantState) {
      case BookingParticipantState.APPROVED:
        return <span className="status-success">Approved</span>;
      case BookingParticipantState.PENDING_APPROVAL:
        return <span className="status-dark">Pending</span>;
      case BookingParticipantState.FITNESS_REJECTED:
        return <span className="status-dark">Rejected</span>;
      case BookingParticipantState.WAITING_LIST:
        return <span className="status-warning">Waiting List</span>;
      default:
        return <span className="status-danger">{bookingParticipantState}</span>;
    }
  };

  const bookingStatusbodyTemplate = (data: any, props: any) => {
    const status = data[props.field];
    return (
      <>
        {status === 'COMPLETED' ? (
          <span className="status-success">Completed</span>
        ) : status === 'PAYMENT' ? (
          <span className="status-dark">Payment</span>
        ) : status === 'ADD_PARTICIPANTS' ? (
          <span className="status-dark">Add Participant</span>
        ) : status === 'WAITING_LIST' ? (
          <span className="status-dark">Waiting List</span>
        ) : status === 'CANCELLED' ? (
          <span className="status-danger">Cancelled</span>
        ) : status === 'DIFFICULT_TREK' ? (
          <span className="status-danger">Difficult Trek</span>
        ) : status === 'INACTIVE' ? (
          <span className="status-warning">Inactive</span>
        ) : (
          <span className="status-warning"> NA </span>
        )}
      </>
    );
  };

  const flagTemplate = (data: any, props: any) => {
    const textColor = getFlagTextColor(data.userDetailsForDisplay, props);
    return (
      <span style={{ color: textColor }} className="p-column-title">
        {data?.userDetailsForDisplay?.flag?.title}
      </span>
    );
  };

  const BookingsParticipantsTableHeader = (
    <div className="table-header">
      <h4 className="p-m-0"></h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e: any) => {
            setGlobalFilter(e.target.value);
          }}
          placeholder="Global Search"
        />
      </span>
    </div>
  );

  const completedTreksbodyTemplate = (data: any, props: any) => {
    return <span>{completedTreks && completedTreks.length}</span>;
  };

  const rowExpansionTemplate = (data: any) => {
    setbookingId(data.id);
    userId = data.userDetailsForDisplay.id;

    return (
      <>
        <div className="orders-subtable">
          {/* eslint-disable react/self-closing-comp */}
          <div>
            <DataTable value={userData}>
              <Column field="trekName" header="Trdek Name" body={bodyTemplate}></Column>
              <Column field="batchStartDate" header="Batch" body={dateFieldTemplate}></Column>
              <Column field="bookingParticipantState" header="Status" body={participantStatusbodyTemplate}></Column>
              <Column field="awards" header="Awards" body={bodyTemplate}></Column>
              <Column field="bookingState" header="Bookingd Status" body={bookingStatusbodyTemplate}></Column>
              <Column
                field="participantComments"
                header="Comments"
                headerClassName="test7"
                body={bodyTemplate}
              ></Column>
            </DataTable>
          </div>
        </div>
      </>
    );
  };

  // @ts-ignore
  return (
    <div>
      <div className="p-grid table-demo">
        <Toast ref={toast} />
        <div className="p-col-12">
          <div>
            <DataTable
              value={participants}
              expandedRows={expandedRows}
              rowExpansionTemplate={rowExpansionTemplate}
              onRowExpand={onRowExpand}
              paginator
              rows={10}
              dataKey="id"
              tabIndex={0}
              rowHover
              selection={selectedParticipants}
              onSelectionChange={(e: any) => setSelectedParticipants(e.value)}
              globalFilter={globalFilter}
              emptyMessage="No record(s) found."
              header={BookingsParticipantsTableHeader}
              loading={loading}
            >
              <Column expander style={{ width: '3em' }} />
              <Column field="firstName" header="Full Name" body={bodyTemplateInnerObjectFullName}></Column>
              <Column field="email" header="Email" body={bodyTemplateInnerObject}></Column>
              <Column
                field="completedTreks"
                header="Number of treks completed"
                body={completedTreksbodyTemplate}
              ></Column>
              <Column field="flag" header="User Flag" body={flagTemplate} sortable />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchRelationshipViewComponent;
