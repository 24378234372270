export enum FeeType {
  TREK = 'TREK',
  BACKPACK_OFFLOADING = 'BACKPACK_OFFLOADING',
  TENT_SHARING = 'TENT_SHARING',
  OFFLINE_BACKPACK_OFFLOADING = 'OFFLINE_BACKPACK_OFFLOADING',
}

export enum WaypointType {
  PICKUP = 'PICKUP',
  DROP_OFF = 'DROP_OFF',
  START_POINT = 'START_POINT',
  END_POINT = 'END_POINT',
}

export enum TravelRouteType {
  PICKUP = 'PICKUP',
  DROP_OFF = 'DROP_OFF',
}

export enum UserTravelState {
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  INITIATED = 'INITIATED',
}
